<mat-nav-list fxLayout class="header-nav__tenant">
  <mat-list-item class="header-nav__link  header-nav__link--tenant" #loggedBtn mat-button #navLogged="matMenuTrigger"
    [matMenuTriggerFor]="tenantMenu" style="z-index: 1050;" hasBackdrop="false">
    {{tenantActive?.name }}
    <i style="margin-left: 15px" aria-hidden="true"
      class="header-nav__link-account-icon icon-css--arrow down small"></i>

    <button mat-mini-fab class="header-nav__link-account-icon-notif" *ngIf="displayExpiration">
      <mat-icon>notifications</mat-icon>
    </button>
  </mat-list-item>
</mat-nav-list>




<mat-menu class="mat-elevation-z1 menu-border header-nav__menu" #tenantMenu="matMenu" xPosition="before"
  [hasBackdrop]="true">
  <!--      <ul style="padding: 0" (mouseenter)="headerService.menuenter()"
  (mouseleave)="headerService.menuLeave(navLogged, loggedBtn, render)"> -->

  <div fxFlex style="text-overflow: ellipsis; overflow-x: clip; text-align: center;">
    <div style="font-size: 14px;"><span> Connecté(e) à : </span><strong>{{tenantActive?.name }}</strong></div>
    <div style="margin-top: 10px; font-size: 12px;"><span> Mon abonnement en cours :
      </span><strong>{{subscription?.nom}}</strong></div>

    <div fxLayout fxLayoutAlign="center center" class=" header-nav__menu-expiration" *ngIf="displayExpiration">
      <mat-icon style="height: 15px; width: 15px; font-size: 15px;">notifications</mat-icon>
      <ng-container *ngIf="timeRemaining > 0">
        <strong>Votre abonnement expire dans {{timeRemaining}} jour(s)</strong>
      </ng-container>
      <ng-container *ngIf="timeRemaining <= 0">
        <strong>Votre abonnement a expiré</strong>
      </ng-container>
    </div>

  </div>


  <hr>
  <ul style="padding: 0">

    <!-- RECRUITERS LOGGED -->

    <ng-container *ngIf="tenantActive">
      <li class="header-nav__menu-item">
        <a fxFlex="100" mat-menu-item fxLayout
          [attr.href]="env.path.blog.mainUrl + '/mon-compte/subscriptions/?tenantid=' + tenantActive.id"
          target="_blank">
          Gérer mon abonnement
        </a>
      </li>

      <!-- <li class="header-nav__menu-item">
        <a fxFlex="100" mat-menu-item fxLayout
          [attr.href]="env.path.blog.mainUrl + '/mon-compte/payment-methods/?tenantid=' + tenantActive.id"
          target="_blank">
          Gérer mes modes de paiement
        </a>
      </li>

      <li class="header-nav__menu-item">
        <a fxFlex="100" mat-menu-item fxLayout
          [attr.href]="env.path.blog.mainUrl + '/mon-compte/payment-methods/?tenantid=' + tenantActive.id"
          target="_blank">
          Accéder à mes factures
        </a>
      </li>

      <li class="header-nav__menu-item">
        <a fxFlex="100" mat-menu-item fxLayout
          [attr.href]="env.path.blog.mainUrl + '/mon-compte/edit-address/?tenantid=' + tenantActive.id" target="_blank">
          Mon adresse de facturation
        </a>
      </li> -->

      <!-- <li>
        <div fxFlex="100" fxLayout class="menu-item-container">
          <strong fxFlex>{{'Mes Sociétés' | uppercase }}</strong><br>
        </div>
      </li> -->


      <!-- <li>
        <a fxFlex="275px" mat-menu-item fxLayout role="button" [title]="tenantActive?.name">
          <div fxFlex fxFlexOffset="15px" style="text-overflow: ellipsis; overflow-x: clip;;">
            <span style="font-weight: 400"> Connecté(e) à : </span>{{tenantActive?.name }}
          </div>
        </a>
      </li> -->


      <!-- <li *ngIf="tenantsList?.length > 1"> -->

      <!-- <hr> -->
      <li>
        <button fxFlex="100" mat-menu-item fxLayout [matMenuTriggerFor]="list">
          Liste de mes sociétés
        </button>

        <mat-menu #list="matMenu" class="header-nav__menu">
          <ng-container *ngFor="let tenant of tenantsList">
            <button mat-menu-item fxFlex="100" (click)="setTenant(tenant)">
              <ng-container *ngIf="tenant?.id === tenantActive?.id || tenant?.id === tenantActive?.tenantId">
                <strong>
                  {{tenant?.name}}
                </strong>
                <span style="font-weight: 400"> : Actif</span>
              </ng-container>


              <span *ngIf="tenant?.id !== tenantActive?.id && tenant?.id !== tenantActive?.tenantId">
                {{tenant?.name}}
              </span>
            </button>
          </ng-container>
          <hr>
          <button mat-menu-item fxFlex="100" [routerLink]="['/mon-compte/tenant']" style="text-align: center">
            <strong style="text-decoration: underline;">Voir mes sociétés<mat-icon
                color="primary">add_circle</mat-icon></strong>
          </button>

        </mat-menu>
      </li>
    </ng-container>

  </ul>
</mat-menu>