<mat-sidenav #accountSidenav mode="side" class="sidenav sidenav-account">
  <div class="sidenav-account__actions" fxLayoutAlign="center center">
    <!--button mat-button [disableRipple]="true" (click)="headerService.toggleMyAccount();sidenav.close();">
      <mat-icon>arrow_back</mat-icon> Revenir
    </button-->
    <div fxFlex fxFlexAlign="flex-start">
      <button mat-button (click)="closeAllNav();" style="padding-left: 0">
        <!-- <mat-icon color="primary" class="icon--xl" aria-hidden="true" aria-label="menu">close</mat-icon> -->
        <mat-icon color="primary" class="icon--xl" aria-hidden="true" aria-label="menu">chevron_left</mat-icon>
      </button>
    </div>
    <div fxFlex="80" class="sidenav-account__actions-name">
      <ng-container *ngIf="authService.loginDisplay">
        <span> {{userDatas?.prenom}} {{userDatas?.nom}}</span>
        <span class="sidenav-account__actions-name__short" *ngIf="!avatarRecruteur">
          <ng-container *ngIf="userDatas?.prenom">
            {{userDatas?.prenom[0] + userDatas?.nom[0]}}
          </ng-container>
        </span>

        <div class="sidenav-account__actions-name__photo" *ngIf="avatarRecruteur">
          <img [src]="'data:image/jpeg;base64,' + avatarRecruteur" alt="" />
        </div>

      </ng-container>
    </div>


  </div>

  <!-- RECRUITERS -->
  <div class="sidenav-account__content" fxLayout="column">

    <ng-container *ngIf="!authService.loginDisplay">
      <div class="sidenav-account__item" fxLayout fxLayoutAlign="center center">
        <!--  <a class="sidenav-account__item-wrapper" title="connexion" [routerLink]="['mon-compte', 'identification']"
            (click)="closeAllNav()"> -->
        <a class="sidenav-account__item-wrapper" title="connexion" (click)="authService.login()">
          <span class="sidenav-account__item-title">Se connecter</span>
        </a>
      </div>

      <!--div class="sidenav-account__item" fxLayout fxLayoutAlign="center center">
          <a class="sidenav-account__item-wrapper" title="inscription" [routerLink]="['inscription']"
            (click)="closeAllNav()">

            <span class="sidenav-account__item-title">S'inscrire</span>
          </a>
        </div-->

    </ng-container>


    <ng-container *ngIf="authService.loginDisplay">
      <ng-container *ngIf="tenantActive">
        <div style="text-overflow: ellipsis; overflow-x: clip; text-align: center;">
          <!-- <div style="font-size: 14px;"><span> Connecté(e) à : </span><strong>{{tenantActive?.name }}</strong></div> -->
          <div style="margin-top: 10px;"><span> Mon abonnement en cours :
            </span><strong>{{subscription?.nom}}</strong></div>

          <div fxLayout fxLayoutAlign="center center" class=" header-nav__menu-expiration" *ngIf="displayExpiration">
            <mat-icon style="height: 15px; width: 15px; font-size: 15px;">notifications</mat-icon>
            <ng-container *ngIf="timeRemaining > 0">
              <strong>Votre abonnement expire dans {{timeRemaining}} jour(s)</strong>
            </ng-container>
            <ng-container *ngIf="timeRemaining <= 0">
              <strong>Votre abonnement a expiré</strong>
            </ng-container>
          </div>

        </div>

        <h3 class="text--center"> MON COMPTE</h3>

        <div class="sidenav-account__item" fxLayout fxLayoutAlign="center center">
          <a class="sidenav-account__item-wrapper" title="mes informations"
            [routerLink]="['/utilisateur', 'mon-compte', 'informations-de-mon-compte']" (click)="closeAllNav()">

            <span class="sidenav-account__item-title">Mes informations</span>
          </a>
        </div>

        <!-- <div class="sidenav-account__item" fxLayout fxLayoutAlign="center center" *ngIf="authService.roles.coordinator">
        <a class="sidenav-account__item-wrapper" title="ma société"
          [routerLink]="['/utilisateur', 'mon-compte', 'ma-societe']" (click)="closeAllNav()">

          <span class="sidenav-account__item-title">Ma société</span>
        </a>
      </div> -->

        <h3 class="text--center"> {{ 'Mes sociétés' | uppercase }}</h3>

        <div fxLayout fxLayoutAlign="center center">

          <div class="" title="ma société" role="button" (click)="closeAllNav()">

            <span class="sidenav-account__item-title" style="font-weight: 300">Connecté(e) à :
              <strong>{{tenantActive?.name }}</strong></span>
          </div>
        </div>

        <br>

        <div *ngFor="let tenant of tenantsList">
          <div class="sidenav-account__item" fxLayout fxLayoutAlign="center center">
            <!-- <a class="sidenav-account__item-wrapper" [attr.title]="tenant?.name"
              (click)="userService.setTenant(tenant, true); closeAllNav();" role="button"> -->
            <a class="sidenav-account__item-wrapper" [attr.title]="tenant?.name"
              (click)="setTenant(tenant); closeAllNav();" role="button">

              <span class="sidenav-account__item-title">{{tenant?.name }}
                <ng-container *ngIf="tenant?.id === tenantActive?.id"> : <span
                    style="font-weight: 300">Actif</span></ng-container>
              </span>
            </a>
          </div>
        </div>

        <!-- <div *ngIf="tenantsList.length > 1"> -->
        <div>
          <div fxLayout fxLayoutAlign="center center" style="padding: 15px;">
            <a style="text-decoration: underline;" title="Voir la liste des sociétés"
              [routerLink]="['/mon-compte/tenant']" (click)="closeAllNav();">
              <span class="sidenav-account__item-title">
                Voir mes sociétés
                <mat-icon>add_circle</mat-icon>
              </span>
            </a>
          </div>
        </div>
      </ng-container>

      <div fxFlex style="flex: 1 1"></div>

      <div class="" fxLayout style="padding: 30px 0; " fxFlexAlign="end" fxLayoutAlign="flex-end flex-end">
        <a (click)="closeAllNav(); authService.logout()">

          <span class="sidenav-account__item-title">
            <mat-icon style="font-size: 20px; margin-right: 10px" class="icon--round icon--round-bg">
              power_settings_new</mat-icon>
            ME DECONNECTER
          </span>
        </a>
      </div>

    </ng-container>


  </div>


</mat-sidenav>