import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '@env';
import { AuthMSALService } from './auth-msal.service';



@Injectable({ providedIn: 'root' })
export class ReferentielService {
  permissionHeader;
  userId;
  url = `${environment.path.api2}/referentiel`;

  constructor(private actRoute: ActivatedRoute, private apollo: Apollo, private http: HttpClient,
    private auth: AuthMSALService) {
    this.permissionHeader = {
      'x-jobology-pre-auth': 'true'
    }

    this.userId = this.auth.getClaims('sub');
  }

  public getContrat = () => {
    return this.http.get(this.url + '/contrat')
  }

  public getContratById = (id) => {
    return this.http.get(`${this.url}/contrat/${id}`)
  }

  public getNiveauEtude = () => {
    return this.http.get(this.url + '/niveauetude')
  }

  public getNiveauEtudeById = (id) => {
    return this.http.get(`${this.url}/niveauetude/${id}`)
  }

  public getNiveauExperience = () => {
    return this.http.get(this.url + '/niveauexperience')
  }

  public getNiveauExperienceById = (id) => {
    return this.http.get(`${this.url}/niveauexperience/${id}`)
  }

  public getLangues = () => {
    return this.http.get(this.url + '/langue')
  }

  public getNiveauLangue = () => {
    return this.http.get(this.url + '/niveaulangue')
  }

  // Auto Complete Country - Localité
  public locationAutocomplete = (startWith, countryCode, rows = 10, locationTypesList?) => {
    let locationTypes = [];
    let url = `${this.url}/localite?q=${startWith}&CountryCode=${countryCode}&rows=${rows}`;

    if (locationTypesList) {
      locationTypes = locationTypesList

      locationTypes.map(el => {
        url = url + '&locationType=' + el;
      })
    }

    return this.http.get(url)
  }

  public autocompleteSavoir = (startWith, rows = 10) => {
    let url = `${this.url}/savoir/annotation?q=${startWith}&rows=${rows}`;

    return this.http.get(url)
  }


  public autocompleteSavoirEtre = (startWith, rows = 10) => {
    let url = `${this.url}/savoiretre/annotation?q=${startWith}&rows=${rows}`;

    return this.http.get(url)
  }

  public autocompleteSavoirFaire = (startWith, rows = 10) => {
    let url = `${this.url}/savoirfaire/annotation?q=${startWith}&rows=${rows}`;

    return this.http.get(url)
  }

  public autocompleteMadSkill = (startWith, rows = 10) => {
    let url = `${this.url}/madskill/annotation?q=${startWith}&rows=${rows}`;

    return this.http.get(url)
  }



  public countries = (continentCode?) => {
    let cCode = continentCode ? continentCode : null;
    let url = `${this.url}/localite/pays`;

    if (cCode) {
      url = url + '?continentCode=' + cCode;
    }

    return this.http.get(url)
  }

  // ANNOTATIONS
  public annotationEntity = () => {
    let url = `${this.url}/typeentiteannotation?`;

    return this.http.get(url)
  }

  public annotationsList = (categorie, rows, page = 1, alignee?, q?) => {
    let rowsInput = 20;

    if (rows) {
      rowsInput = rows
    }

    let url = `${this.url}/${categorie}/annotations?rows=${rowsInput}&page=${page}`;

    if (alignee) {
      url = url + '&alignees=' + alignee;
    }

    if (q) {
      url = url + '&q=' + q;
    }

    return this.http.get(url)
  }

  public annotationType = (categorie, moderation?) => {
    let url = `${this.url}/typeannotation?`;

    if (moderation) {
      url = url + 'moderation=true';
    }

    return this.http.get(url)
  }


  public updateAnnotationAlignement = (categorie, datas) => {
    let url = `${this.url}/${categorie}/alignement`;

    let input = {
      [`${categorie}AnnotationId`]: datas[`${categorie}AnnotationId`],
      [`${categorie}Id`]: datas[`${categorie}Id`],
      langueId: datas.langueId,
      typeAnnotationId: datas.typeAnnotationId,
      annotation: datas.annotation
    }

    return this.http.put(url, input)
  }

}
